<template>
  <ModalMainNew media-type="tx" @close="closeModal">
    <div class="animate-fade-in w-full">
      <div class="text-center block">
        <img
          class="w-full"
          src="~/assets/img/posters/redeem-code.jpg"
          width="448"
          height="164"
          alt="Redeem Your Boost Code"
        >
        <form v-if="!isSuccessful" class="px-5 pt-4" @submit.prevent="confirmCode">
          <input
            v-model="codeInput"
            :disabled="isProcessing"
            class="block relative w-full rounded-lg py-3 px-4 border-2 outline-none focus:ring-0 appearance-none hover:appearance-none disabled:text-slate-100 text-sm text-left transition-all duration-200 bg-black/50 border-slate-100/20 autofill:bg-slate-900"
            type="text"
            placeholder="Enter promo code"
            @input.prevent="onInput"
          >
          <p class="text-red text-left text-xs mt-2 pl-1">
            {{ validationMessage }}
          </p>
          <ButtonButton
            :disabled="isButtonDisabled || isProcessing"
            :is-loading="isProcessing"
            type="submit"
            class="block w-full mt-4 mb-4"
            size="sm"
            autocomplete="off"
          >
            Redeem
          </ButtonButton>
        </form>
        <div v-else class="px-5 pt-4">
          <h1 class="text-xl font-bold pb-1">{{ data.heading }}</h1>
          <p class="pb-1 text-sm">{{ data.message }}</p>
          <p class="pb-3 text-sm">Reward: <span class="text-green-400 font-bold">{{ amount }} {{ currencyCode }}</span></p>
          <ButtonButton
            class="block w-full mb-4 bg-[#363A46]"
            size="sm"
            autocomplete="off"
            theme="alternative-grey-light"
            @click.prevent="closeModal"
          >
            Close
          </ButtonButton>
        </div>
      </div>
      <div class="w-full text-center px-5 pb-5">
        <div class="border-t border-slate-300 pt-4">
          <p class="text-[#B1BAD3] text-xs md:text-sm mb-2">{{ data.footerContent }}</p>
          <NuxtLink
            :to="data.messageLink"
            target="_blank"
            class="underline text-[#B1BAD3] cursor-pointer text-xs md:text-sm "
            @click="closeModal"
          >
            {{ data.messageLinkContent }}
          </NuxtLink>
        </div>
      </div>
    </div>
  </ModalMainNew>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useUiStore } from '@/store/ui';

const uiStore = useUiStore();
const { showRedeemModal, walletModalData, } = storeToRefs(uiStore);

const codeInput = ref('');
const isProcessing = ref(false);
const isSuccessful = ref(false);
const amount = ref(null);
const currencyCode = ref(null);
const validationMessage = ref(null);
const data = ref(null);

const { $api, $rollbar, $dayjs, $sanity, } = useNuxtApp();

const isButtonDisabled = computed(() => codeInput.value.length < 1);

function onInput() {
  if (validationMessage.value) {
    validationMessage.value = null;
  }
}

async function confirmCode() {
  isProcessing.value = true;

  try {
    const res = await $api('/reward/claim', {
      method: 'POST',
      body: {
        code: codeInput.value,
      },
    });

    isSuccessful.value = true;
    amount.value = res.amount;
    currencyCode.value = res.currencyCode;
  } catch (err) {
    setValidationMessage(err.data);
    $rollbar.error('Failed POST /reward/claim redeem modal', err);
  } finally {
    isProcessing.value = false;
  }
}

function setValidationMessage(errorCode) {
  if ([400, 404,].includes(errorCode.status)) {
    validationMessage.value = 'This boost code is not available';
    return;
  }

  switch (errorCode.code) {
    case 1000:
      validationMessage.value = 'This boost code is not active';
      break;
    case 1001:
      validationMessage.value = `You're too late; this bonus code expired ${$dayjs(errorCode?.expiryTime).fromNow()}`;
      break;
    case 1002:
      validationMessage.value = `You're too late; this bonus code has reached its maximum claims of ${errorCode?.threshold}.`;
      break;
    case 2000:
      validationMessage.value = 'You\'ve claimed this reward the maximum number of times';
      break;
    case 2002:
      validationMessage.value = `You need to have made at least ${errorCode?.eligibility?.total} deposits with your account`;
      break;
    case 2003:
      validationMessage.value = 'Your first deposit date isn’t within the period of the bonus code';
      break;
    case 2004:
      validationMessage.value = 'The same IP address cannot claim reward more than once';
      break;
    case 2005:
      validationMessage.value = `You need to wager ${errorCode?.eligibility?.minAmount} ${errorCode?.eligibility?.currencyCode} between ${$dayjs(errorCode?.eligibility?.startTime).format('D MMMM YYYY')} and ${$dayjs(errorCode?.eligibility?.endtime).format('D MMMM YYYY')}.`;
      break;
    default:
      validationMessage.value = 'You\'re not eligibile for the supplied bonus code';
  }
}

function closeModal() {
  showRedeemModal.value = false;
  walletModalData.value = null;
}

async function fetchData() {
  try {
    const res = await $sanity.fetch(`*[_type == "modals" && modalId == "RedeemCode"][0] {
      heading,
      messageLink,
      messageLinkContent,
      message,
      footerContent,
      buttonLabel
    }`);
    if (res) {
      data.value = res;
    }
  } catch (err) {
    $rollbar.error('fetchData CMS modal data::', err);
  }
}

async function init() {
  if (walletModalData.value?.code) {
    codeInput.value = walletModalData.value.code;
  }

  await fetchData();
}

await init();

</script>
